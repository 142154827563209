import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface NotificationProps {
    message: string;
  }
  
  const Notification: React.FC<NotificationProps> = ({ message }) => {
    
    const theme = useTheme();
    const { t } = useTranslation();

    return(
        <Box style={{
            backgroundColor: 'rgba(255, 0, 0, 0.7)',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
        }}>
          {t(message)}
        </Box>
    )
};
  


  export default Notification;