import { MapContainer , TileLayer} from 'react-leaflet'
import React from 'react';
import { useState } from 'react';
import { Location , RouteDataPoints,} from '../../types';
import MapEventHandler from './MapEventHandler';
import PinMarker from './PinMarker';
import MapPoints from './MapPoints';
import MapRoute from './MapRoute';
import NotificationList from "../Notifications/NotificationList";
import MapSelector from './MapSelector';

const mapTiles = [
  { key: 'osm', url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png" },
  { key: 'carto', url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" },
]


const MapViewer: React.FC<{locations: Location[] , route_data: RouteDataPoints | null}> = ({locations, route_data}) => {
     
   const defaultTile = mapTiles.find(tile => tile.key === 'carto')?.url || '';
     
     const [radius, setRadius] = useState<number>(5);
     const [tile , setTile] = useState(defaultTile);
     const notifications = [];

    const locationsWithoutCoordinates = locations.filter(location => location.lat == null || location.lng == null);

      //Notifications
      if (!route_data?.pin) {
        notifications.push("map_current_localization_is_not_available");
      }

      if (!route_data?.route || route_data.route.length == 0) {
        notifications.push("map_route_data_is_not_available");
      }

      if(locationsWithoutCoordinates.length > 0){
        notifications.push("map_incomplete_location_data");
      }


    return(
      <MapContainer center={route_data?.pin ? route_data.pin : [52.0693,19.4803]} zoom={3} style={{ height: '100%', width: '100%' ,display: 'flex'}} attributionControl={false}  zoomControl={true}>
            <TileLayer 
                minZoom={2}
                url={tile}
               attribution=''// '&copy; <a href="<http://osm.org/copyright>">OpenStreetMap</a> contributors' //turn off contribution
              />  
 
            <MapEventHandler setRadius={setRadius} /> 
            <MapPoints locations={locations}  /> 

         
      {route_data?.route ? (
        <>
         <MapRoute route_data={route_data} />  

         {route_data.pin ?  
             <PinMarker pin={route_data.pin} radius={radius} /> 
             : 
             <NotificationList messages={notifications} />
           }
        </>
      ) : 
        <NotificationList messages={notifications} />
      }  

       <MapSelector setTile = {setTile} mapTiles={mapTiles}/>

    </MapContainer>
    )
}

export default MapViewer;