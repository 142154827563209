import { Typography } from "@mui/material"
import dayjs from 'dayjs';
import i18n from '../../helpers/i18n';
import 'dayjs/locale/pl';


const EventDate: React.FC<{date: string | null}> = ({date}) =>{

   const currentLocale = i18n.language;
   dayjs.locale(currentLocale);

    let formattedDate = dayjs(date).format('D MMMM YYYY HH:mm');
    
       return(
          <Typography variant="body1" fontSize={12} component="span" >
                {formattedDate}
          </Typography>
       )
}


export default EventDate;