import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import {lightBlue} from '@mui/material/colors';
import { I18nextProvider } from 'react-i18next';
import i18n from './helpers/i18n';

const theme = {
   palette:{
    primary:{
      main: grey[900],
      light: grey[500],
    },
    secondary:{
       main: lightBlue[700],
       light: lightBlue[100],
       dark: lightBlue[900],
    }
   },
   typography: {
    fontFamily: '"Roboto", sans-serif',
  },
}

const appTheme = createTheme(theme);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <I18nextProvider i18n={i18n}>
       <App />
       </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);


reportWebVitals();
