import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Box, Typography } from '@mui/material'
import {HorizontalPointsBox, HorizontalStepper, HorizontalStepConnector} from '../../styles/TimeLineStyles';
import {SortedRoutePoint} from '../../types';
import HorizontalStepIcon from './HorizontalStepIcon';
import { useTranslation } from 'react-i18next';


const HorizontalTimeLine: React.FC<{routePoints: SortedRoutePoint[] , activeStep: number }> = ({routePoints , activeStep}) => {
   
  const { t } = useTranslation();

  return(
      <Box sx={{ width: '100%', pt:3 }} >
        <HorizontalPointsBox>
              {routePoints.map((route)=>{
                   return route.locationData ? (
                    <Typography key={route.location}>
                         {route.locationData.name !== null ?  t(route.locationData.name) + "," : route.locationData?.locode } 
                         
                         { route.locationData?.locode && !route.locationData.name ? "" : route.locationData.country_code}
                    </Typography>
                  ) : (
                    <Typography key={route.location}>{t("hortimeline_location_data_not_available")}</Typography>
                  );
              })}                     
        </HorizontalPointsBox>

        <HorizontalStepper activeStep={activeStep}  connector={<HorizontalStepConnector />  } >
            {routePoints.map((route,index) => (
                <Step key={index}>
                  <StepLabel StepIconComponent={ HorizontalStepIcon }>
                  </StepLabel>
                </Step> 
            ))}
        </HorizontalStepper>
      </Box>
  )

}


export default  HorizontalTimeLine;