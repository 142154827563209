
import {Typography} from '@mui/material';
import {ContainerStatus} from '../../types';
import { getEnumValue } from '../../helpers/infoSideBarHelper';
import { useTranslation } from 'react-i18next';



const EventStatus: React.FC<{status: string | null }> = ({status}) =>{
    
    const { t } = useTranslation();
    const container_status = getEnumValue(status, ContainerStatus);

    return(
        <Typography variant="body1" fontSize={12} component="span">
            {t(container_status)}
        </Typography>
    )
} 


export default EventStatus;