import { Dispatch, SetStateAction, } from 'react';

  interface RouteData {
     prepol : RoutePoint,
     pol: RoutePoint,
     pod: RoutePoint,
     postpod: RoutePoint,
  }
  
  interface RoutePoint {
      location : number | null,
      date: string | null,
      actual : boolean | null,
  }

  interface SortedRoutePoint extends RoutePoint{
      locationData:  Location | null
  }

  interface Location {
     id: number,
     name: string | null,
     state: string | null,
     country: string | null,
     country_code: string | null,
     locode: string | null,
     lat: number | null,
     lng: number | null
  }

  type Locations = Location[]
  
  //Verical timeLine
  interface Event{
     location: number,
     description: string | null,
     status: string | null,
     date: string | null,
     actual: boolean | null,
     type: string | null,   //[SEA,lAND]
     transport_type: string | null, //[ VESSEL, BARGE, FEEDER, TRUCK, RAIL, AIR ]
     vessel : number | null,
     voyage: string | null
  }

  interface Container{
      number: string,
      iso_code: string | null,
      status: string | null,
      size_type: string | null,
      events: Event[] | null,
  }

  type Containers = Container[];

  interface LocationsWithEvents { //helper
   location: Location,
   events: Event[]

}


interface VesselWithEvents { 
   vessel: Vessel,
   events: Event[]
}

 interface Credentials{
   user: string,
   password: string,
   session_id: string,
   container_number: string,
   language: string | null,
   reference_id: string | null
 }



// Statuses codes 
export enum ContainerStatus {
   CEP = "CEP",
   CPS = "CPS",
   CGI = "CGI",
   CLL = "CLL",
   VDL = "VDL",
   VAT = "VAT",
   CDT = "CDT",
   TSD = "TSD",
   CLT = "CLT",
   VDT = "VDT",
   VAD = "VAD",
   CDD = "CDD",
   CGO = "CGO",
   CDC = "CDC",
   CER = "CER",
   LTS = "LTS",
   BTS = "BTS",
   UNK = "UNK"
}


enum ObjectTypes {
   CT = "CT",
   BL = "BL",
   BK = "BK"
}


enum ContainerStatuses {
   PLANNED = "status_planned", 
   IN_TRANSIT = "status_in_transit", 
   DELIVERED = "status_delivered", 
   UNKNOWN = "status_unknown" 
}


interface ApiCalls{
    total:  number| null,
    used:  number| null,
    remaining: number |  null
}

interface UniqueShipments{
   total: number | null,
   used: number | null,
   remaining: number | null
}

interface Vessel{
    id: number,
    name: string,
    imo: number | null,
    call_sign: string | null,
    mmsi: number | null,
    flag: string | null,

}

type Vessels = Vessel[] | null;

interface Metadata {
     type: string,
     number: string,
     sealine: string | null,
     sealine_name: string | null,
     status: string | null,
     is_status_from_sealine: boolean,
     updated_at: string | null,
     cache_expires: string | null,
     api_calls: ApiCalls
     unique_shipments: UniqueShipments,
   
}

interface Data {
   metadata: Metadata,
   locations: Locations,
   route: RouteData,
   vessels: Vessels,
   containers: Containers, 
   route_data: RouteDataPoints | null,
}

interface Raw{
    data: Data,
    message: string,
    status: string,
}

interface TrackItem {
     id: number,
     number: string,
     reference_id: string | null,
     raw: Raw
     session_id: number,
     updated_at: string,
     start_time: string | null,
     end_time: string | null,
}




interface RouteDataPoints{
    ais: Ais | null,
    pin: Cordinate | null,
    route: Route[] | null,
}

interface Ais{
    status: string | null,
    data: AisData | null 
}


interface AisData{
   last_event: AISLastEvent,
   discharge_port: AISPort,
   vessel:  AISVessel,
   last_vessel_position:  AISLastVesselPosition,
   departure_port: AISPort,
   arrival_port: AISPort,
   updated_at: string|null
}

interface AISLastEvent{
   description: string | null,
   date: string | null,
   voyage: string |null
}

interface AISPort{
   name: string |null,
   country_code: string | null,
   code: string | null,
   date: string | null,
   date_label: string | null,
}

interface AISVessel{
    name:  string | null,
    imo:  string | null,
    call_sign:  string | null,
    msi:  string | null,
    flag:  string | null,
}

interface AISLastVesselPosition{
    lat: number,
    lng: number,
    updated_at: string,
}



type Cordinate = [number, number];

interface Route{
    path: Cordinate[],
    type: string | null,
    transport_type: string | null
}


    enum ContainerTypes {
      "20G0" = "20GP",
      "20G1" = "20GP",
      "20H0" = "20HR",
      "20P1" = "20PF",
      "20T3" = "20TD",
      "20T4" = "20TD",
      "20T5" = "20TD",
      "20T6" = "20TD",
      "20T7" = "20TG",
      "20T8" = "20TG",
      "20T0" = "20TN",
      "20T1" = "20TN",
      "20T2" = "20TN",
      "22B0" = "22BU",
      "22G0" = "22GP",
      "22G1" = "22GP",
      "22H0" = "22HR",
      "22P3" = "22PC",
      "22P8" = "22PC",
      "22P9" = "22PC",
      "22P1" = "22PF",
      "22P7" = "22PF",
      "22R9" = "22RC",
      "22R7" = "22RS",
      "22R1" = "22RT",
      "22S1" = "22SN",
      "22T3" = "22TD",
      "22T4" = "22TD",
      "22T5" = "22TD",
      "22T6" = "22TD",
      "22T7" = "22TG",
      "22T8" = "22TG",
      "22T0" = "22TN",
      "22T1" = "22TN",
      "22T2" = "22TN",
      "22U6" = "22UP",
      "22U1" = "22UT",
      "22V0" = "22VH",
      "22V2" = "22VH",
      "22V3" = "22VH",
      "25G0" = "25GP",
      "26G0" = "26GP",
      "26H0" = "26HR",
      "28T8" = "28TG",
      "28U1" = "28UT",
      "28V0" = "28VH",
      "29P0" = "29PL",
      "2EG0" = "2EGP",
      "42G0" = "42GP",
      "42G1" = "42GP",
      "42H0" = "42HR",
      "42P3" = "42PC",
      "42P8" = "42PC",
      "42P9" = "42PC",
      "42P1" = "42PF",
      "42P6" = "42PS",
      "42R9" = "42RC",
      "42R3" = "42RS",
      "42R1" = "42RT",
      "42S1" = "42SN",
      "42T5" = "42TD",
      "42T6" = "42TD",
      "42T8" = "42TG",
      "42T2" = "42TN",
      "42U6" = "42UP",
      "42U1" = "42UT",
      "45B3" = "45BK",
      "45G0" = "45GP",
      "45G1" = "45GP",
      "45P3" = "45PC",
      "45P8" = "45PC",
      "45R9" = "45RC",
      "45R1" = "45RT",
      "45U1" = "45UT",
      "45U6" = "45UP",
      "46H0" = "46HR",
      "48T8" = "48TG",
      "49P0" = "49PL",
      "4CG0" = "4CGP",
      "L0G1" = "L0GP",
      "L2G1" = "L2GP",
      "L5G1" = "L5GP"
    }

interface UrlParams{
    session_id: string | null,
    number: string | null,
    instance_name: string | null,
    language: string | null,
    reference_id: string | null
    monitoring_status: string | null
}

//MapSelector component

interface MapTile {
      key: string;
      url: string;
   }

type MapTiles = MapTile[];


interface MapSelectorProps {
   setTile: Dispatch<SetStateAction<string>>;
   mapTiles: MapTiles;
 }

 type MonitoringStatuses = 'CP' | 'IN' | 'DL'| 'UNKN';

export type{ 
      RoutePoint,
      RouteData,
      Locations,
      Location,
      Container,
      Containers,
      Event,
      LocationsWithEvents,
      SortedRoutePoint,
      Metadata,
      Data,
      Vessel,
      VesselWithEvents,
      TrackItem,
      Cordinate,
      RouteDataPoints,
      Credentials,
      UrlParams,
      Vessels,
      MapTiles,
      MapSelectorProps,
      MonitoringStatuses
     };

export {
   ContainerStatuses,
   ContainerTypes,
   ObjectTypes,
}


