import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import { Box } from '@mui/material';




//Horizontal Time Line
const HorizontalStepper = styled(Stepper)(({theme})=>({
    '& .MuiStep-root': {
        paddingLeft: 0,
        paddingRight: 0,
        '& .MuiStepLabel-root': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
      '& .MuiStepLabel-root': {
        padding: 0,
        mr:0, 
      },
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0,
      },
      '& .MuiStepLabel-labelContainer': {
        paddingTop: '0px', 
      }
}));



  const HorizontalStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        borderTop: '2px',
        borderRadius: 1,
        backgroundColor: theme.palette.primary.main
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        borderTop: '2px',
        borderRadius: 1,
        backgroundColor: theme.palette.primary.main
      },
    },
    [`&.${stepConnectorClasses.disabled} .${stepConnectorClasses.line}`]: {
      borderTop: '2px dotted',
      borderTopColor: theme.palette.primary.light ,
      
    },
  }));



   const TimeLineStepIcon = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
     }>(({ theme, ownerState }) => ({
    zIndex: 1,
    width: 15,
    height: 15,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
  }));
  

const HorizontalPointsBox = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 2
  }));

//Vertical TimeLine
const VerticalStepper = styled(Stepper)(({theme})=>({
    "& .MuiStep-root": {
      "& .MuiStepLabel-root": {
        padding: 0,
        minHeight: '20px',
      },

    },
    "& .MuiStepConnector-root": {
      marginLeft: "7.5px",
      
    },
    "& .MuiStepConnector-root.MuiStepConnector-vertical.Mui-disabled": {
      "& .MuiStepConnector-line": {
          borderColor: theme.palette.grey[500],
          borderLeft: '2px dotted',
          borderLeftColor: theme.palette.grey[500],
           
      }
    },

    "& .MuiStepContent-root": {
      borderColor: theme.palette.secondary.main,
      borderLeftWidth: "2px",
      marginLeft: "7.5px",
      paddingLeft: "16px",

    },
  }))


const VerticalStepConnector = styled(StepConnector)(({ theme }) => ({
    "& .MuiStepConnector-line": {
      minHeight: "12px",
      margin: "-1px",
      borderColor: theme.palette.grey[500], 
      borderLeftWidth: "2px",
      padding: 0  
    },
    "&.MuiStepConnector-active .MuiStepConnector-line": {
      borderColor: theme.palette.secondary.main
    },
    "&.MuiStepConnector-disabled .MuiStepConnector-line": {
      borderColor: theme.palette.grey[500],
    },
    '&.Mui-disabled': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.grey[500], 
      },
    },
    "&.MuiStepConnector-root.MuiStepConnector-vertical.Mui-active":{
        '& .MuiStepConnector-line': {
          borderColor: theme.palette.secondary.main,
          marginBottom: "-1px",
        }
    }
    
  }));


  const VerticalStepConnectorIcon = styled(StepConnector)<{ active: boolean | undefined | number}>(({ active ,theme  }) => ({
    "& .MuiStepConnector-line": {
      //minHeight: "19px",
      height: "30px",
      padding: 0,
      margin: "-1px",
      borderLeftWidth: "2px",
      borderLeft: active ? "2px solid" : '2px dotted',
      borderLeftColor: active == true ?theme.palette.secondary.main : theme.palette.grey[500] ,
       marginLeft: "-1px",
       paddingRight: "8px",
      borderColor: active == true ? theme.palette.secondary.main : theme.palette.grey[500], 
      
      [theme.breakpoints.up('xs')]: {
        height: "65px",
      },
      [theme.breakpoints.up('sm')]: {
        height: "60px",
      },
      [theme.breakpoints.up('md')]: {
        height: "45px",
      },
      [theme.breakpoints.up('lg')]: {
        height: "38px",
      },
      [theme.breakpoints.up('xl')]: {
        height: "20px",
      },
    },
    "&.MuiStepConnector-active .MuiStepConnector-line": {
      borderColor: active == true ? theme.palette.secondary.main : theme.palette.grey[500] ,
    },
    "&.MuiStepConnector-root.MuiStepConnector-vertical.Mui-disabled": {
     borderColor: theme.palette.grey[500],
     borderLeftColor: theme.palette.grey[500],
    },
    "&.MuiStepConnector-root.MuiStepConnector-vertical.Mui-active":{
       borderColor: theme.palette.grey[500],
       borderLeftColor: active == true ? theme.palette.secondary.main : theme.palette.grey[500] ,
    },
    '&.Mui-disabled': {
      
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.grey[500],
        borderLeft: '2px dashed', 
        borderLeftColor: theme.palette.grey[500],
      },
    },
    
  }));





export {
    HorizontalStepper,
    HorizontalStepConnector,
    HorizontalPointsBox,
    VerticalStepper,
    VerticalStepConnector,
    VerticalStepConnectorIcon,
    TimeLineStepIcon
}