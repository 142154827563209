import { StepIconProps } from '@mui/material/StepIcon';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { TimeLineStepIcon } from '../../styles/TimeLineStyles';



function HorizontalStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
      
    let iconColor: 'inherit' | 'primary' | 'disabled' = 'inherit';
  
     if(!active && !completed ){
           
      iconColor = 'disabled';
     }else{
      iconColor = 'primary';
     }
     
  
    return (
      <TimeLineStepIcon ownerState={{ completed, active }} className={className}>
         { active ?   <ArrowCircleRightOutlinedIcon fontSize="small" />  :  <TripOriginIcon  sx={{fontSize: 18}} color={iconColor}  /> }
      </TimeLineStepIcon>
    );
  }

export default HorizontalStepIcon;