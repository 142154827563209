import {CssBaseline} from '@mui/material'
import { useState, useEffect } from 'react';
import { getParamsFromUrl,handleTokenRefreshAndTracking,} from '../services/authService'
import {Loader,LoaderBox} from '../styles/InfoSideBarStyles';
import GridItems from '../components/GridItems';
import { TrackItem, Credentials, MonitoringStatuses } from '../types';
import { setMonitoringStatus } from '../helpers/infoSideBarHelper';
import ErrorBox  from '../components/ErrorBox';
import i18n from 'i18next';


export default function Home(){
  const [monitoringStatusFromTms, setMonitoringStatusFromTms] = useState<MonitoringStatuses>('UNKN');
  const [trackItem , setTrackItem] = useState<TrackItem | null>(null);
  const [error , setError] = useState('');
  const [loading , setLoading] = useState(true);
  const [language, setLanguage] = useState<string | null>('pl');

  
  useEffect(() => {
     
    getParamsFromUrl().then((urlParams)=>{

      const {session_id , number, instance_name ,language , reference_id , monitoring_status} = urlParams
         
      const user = process.env[`REACT_APP_INSTANCE_${instance_name}_USER_NAME`];
      const password = process.env[`REACT_APP_INSTANCE_${instance_name}_PASSWORD`];
 
           if (!user || !password) {
             setError('auth_invalid_credentials');
             setLoading(false);
             return;
           }
 
         if(user && password && session_id && number){
 
           let credentials: Credentials ={
              user: user,
              password: password,
              session_id: session_id,
              container_number: number,
              language: language,
              reference_id: reference_id
            }

            i18n.changeLanguage(language ?? 'pl');

           setMonitoringStatusFromTms(setMonitoringStatus(monitoring_status));
           
           handleTokenRefreshAndTracking( credentials ,setTrackItem, setLoading , setError, setLanguage); 
         }
          
    })
    .catch((error)=>{
        setError('auth_error_fetching_url_params');
        setLoading(false);
    })
       

      }, []);
     

 return(

    <>
    {loading &&  <LoaderBox>
                     <Loader/>
                </LoaderBox>
    }

    {error && <ErrorBox error={error}  />}

    {!loading && !error && trackItem && (
        <>
        <CssBaseline />
        <GridItems  trackItem={trackItem}  language={language} monitoring_status={monitoringStatusFromTms}/>
        </>
    )}
  </>
 )

}
