import { Event} from '../../types';
import { Grid } from '@mui/material'
import EventStatus from './EventStatus'
import EventDate from './EventDate'

const VerticalTimeLineEvent: React.FC<{event: Event }> = ({event}) =>{

  return(
    <Grid container justifyContent="space-between" alignItems="baseline" >
        <Grid item >
            <EventStatus status={event.status} />
        </Grid>
        <Grid item  >
            <EventDate date={event.date} />
        </Grid>
    </Grid>
  )
 
}


export default VerticalTimeLineEvent;