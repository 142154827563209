import {Vessel, Event, VesselWithEvents} from '../types';
import dayjs from 'dayjs';


const getVesselsWithEvents = (vessels: Vessel[], events: Event[] | undefined): VesselWithEvents[] => {

        const vesselsWithEmptyEvents: VesselWithEvents[] = vessels.map(vessel => ({
          vessel: vessel,
          events: []
        }));

        if(events)
            events.map((event:Event) => {
              const index = vesselsWithEmptyEvents.findIndex(item => item.vessel.id === event.vessel);
              if (index !== -1) {
          
                vesselsWithEmptyEvents[index].events.push(event);
                vesselsWithEmptyEvents[index].events.sort((a, b) => new Date(a.date??"").getTime() - new Date(b.date??"").getTime());
              }
            });
        
        const sortedVessels = vesselsWithEmptyEvents.sort((a, b) =>
          new Date(a.events[0]?.date ?? 0).getTime() - new Date(b.events[0]?.date ?? 0).getTime()
        );
  
    return sortedVessels;
  };


  const formatDate = (date: string): string => {
    return dayjs(date).format('D MMMM YYYY');
  };


  export{
    getVesselsWithEvents, 
    formatDate
  }