import { Location , Event ,LocationsWithEvents} from '../types';


const getLocationsWithEvents = (locations: Location[], events: Event[] | undefined): LocationsWithEvents[] => {
  
        const locationsWithEmptyEvents: LocationsWithEvents[] = locations.map(location => ({
          location: location,
          events: []
        }));

        let events_with_date: Event[] = [];
        
        //Filter events which have date
        if(events){
            events_with_date = events?.filter( event => event.date !== null )
        }
        
        //Set events to the location
        if(events_with_date.length > 0 ){

          events_with_date.map((event:Event) => {
              const index = locationsWithEmptyEvents.findIndex(item => item.location.id === event.location);
              if (index !== -1) {

                locationsWithEmptyEvents[index].events.push(event);
                locationsWithEmptyEvents[index].events.sort((a, b) => new Date(a.date??"").getTime() - new Date(b.date??"").getTime());
              }
            });
      }

      //Check if events are not empty array and filter
      let locationsWithEvents = locationsWithEmptyEvents.filter(item => item.events.length > 0);

        const sortedLocations = locationsWithEvents.sort((a, b) =>
         new Date(a.events[0].date??"").getTime() - new Date(b.events[0].date??"").getTime()
       );

  return sortedLocations;
};



const isStepCompleted = (stepIndex: number, activeStep: number): boolean => {
    return stepIndex < activeStep;
  };


const isStepDisabled = (stepIndex: number, activeStep: number): boolean => {
    return stepIndex > activeStep;
  };


const findLastActualEvent = (events: Event[]): Event | undefined => {

        const reversedEvents = events.slice().reverse();
        const lastActualEvent = reversedEvents.find(item => item.actual);

    return lastActualEvent;
  }


export{
     getLocationsWithEvents,
     isStepCompleted,
     isStepDisabled,
     findLastActualEvent,
}