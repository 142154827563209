import { useMapEvents  } from 'react-leaflet'


interface MapEventHandlerProps{
    setRadius: React.Dispatch<React.SetStateAction<number>>
  }
  
  const MapEventHandler: React.FC<MapEventHandlerProps>  = ({ setRadius }) => {
    const map = useMapEvents({
      zoomend: () => {
        const zoom = map.getZoom();
        setRadius(Math.max(5, 20 - zoom)); 
      },
    });
  
    return null;
  };

export default MapEventHandler