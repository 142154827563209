import React, { Fragment } from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { VerticalStepper,VerticalStepConnector,VerticalStepConnectorIcon} from '../../styles/TimeLineStyles';
import { PaperInfoNoData } from '../../styles/InfoSideBarStyles';
import {Typography} from '@mui/material';
import { Event, LocationsWithEvents } from '../../types';
import { useState, useEffect } from 'react';
import { isStepCompleted, isStepDisabled, findLastActualEvent } from '../../helpers/eventHelpers';
import VericalLineStepIcon from './VerticalStepIcon';
import VerticalTimeLineEvent from './VerticalTimelineEvent';
import { useTranslation } from 'react-i18next';





  const VerticalTimeLine: React.FC<{locations_with_events:LocationsWithEvents[] }>= ({locations_with_events}) => { 

    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [activeEvent, setActiveEvent] = useState<Event | {}>({});
    const [orderedEvents , setOrderedEvents] = useState<LocationsWithEvents[] | []>([]);
    

   
   useEffect(()=>{

        let events = locations_with_events

        setOrderedEvents(events);
        
        //Find last active location
        const index = events.findIndex(item => item.events.length > 0 && item.events[0].actual === false);
        let activeLocationIndex;
           
        if (index !== -1) {
            setActiveStep(index - 1);
            activeLocationIndex = index-1;
        } else {
            setActiveStep(events.length - 1); 
            activeLocationIndex =events.length - 1 ;
        }
         
        // Find last active event in last active location
        if(activeLocationIndex !== -1){
          const lastActualEvent = findLastActualEvent(events[activeLocationIndex].events)

          if(lastActualEvent){
            setActiveEvent(lastActualEvent)
          }
        } 

        
      },[locations_with_events])


    return(
       <>
       { orderedEvents && orderedEvents.length > 0 ?
         <VerticalStepper  orientation="vertical" connector={<VerticalStepConnector />} activeStep={activeStep} >
          { orderedEvents.map((event, index) => (
              <Step  key={`${event.location.id}-${index}`}
                     active={index == activeStep }
                     completed={isStepCompleted(index,activeStep)}
                     disabled ={isStepDisabled(index,activeStep)}
                     >
                  <StepLabel key={`empty-${event.location.id}-${index}`} StepIconComponent={ (props) => <VericalLineStepIcon custom_icon="location"  {...props} />} sx={{paddingBottom:6 }}>
                      <Typography variant="subtitle2" sx={{color: 'secondary.main',}}> 
                           { event.location?.name !== null ? event.location?.name + ","  :  event.location?.locode } 
                           
                           {event.location?.locode && !event.location.name ? "" : event.location.country_code}  
                      </Typography>
                  </StepLabel>

                  <StepLabel StepIconComponent={(props) => ( <VerticalStepConnectorIcon  active={props.completed || props.active ? 1: 0} /> )} />
                 
                    {event.events.map((event,index) => (
                    <Fragment key={index}>
                      { event ===  activeEvent ?  
                            <StepLabel key={`active-${index}`} StepIconComponent={(props) => <VericalLineStepIcon custom_icon="event"   {...props} />}  >
                                <VerticalTimeLineEvent event={event}  />
                            </StepLabel>  
                            :  
                            <StepLabel key={`inactive-${index}`}  StepIconComponent={ () => <VerticalStepConnectorIcon active={event.actual ? +event.actual : 0}  /> } >
                                <VerticalTimeLineEvent event={event}  />
                            </StepLabel>
                        }
                    </Fragment>
                    ))}
              </Step>
          ))}
        </VerticalStepper>
        : 
         <PaperInfoNoData elevation={1} > {t("vertimeline_no_information_about_route")} </PaperInfoNoData>
        }
      </>
       
    );
  }


export default  VerticalTimeLine;


