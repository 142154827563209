import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import VerticalTimeLine from '../VerticalTimeLine/VerticalTimeLine';
import { Locations, LocationsWithEvents, VesselWithEvents} from '../../types';
import VesselComponent from '../VesselComponent';
import CustomTabPanel from './CustomTabPanel';
import { useTranslation } from 'react-i18next';




function a11yProps(index: number){
     return {
         id: `simple-tab-${index}`,
         'aria-controls': `simple-tabpanel-${index}`
     }
}


const CustomTabs :React.FC<{locations_with_events: LocationsWithEvents[] , vessels_with_events: VesselWithEvents[] , locations: Locations}> = ({locations_with_events, vessels_with_events , locations}) => {
     
     const { t } = useTranslation();
     const [value,setValue]  = useState<number>(0);

     const handleChange = (event: React.SyntheticEvent , newValue: number) =>{
         setValue(newValue);
     }

     return(
         <Box sx={{ width: '100%' , paddingTop:2}} >
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}> 
                <Tabs value={value} onChange={handleChange} aria-label="basic-tab-example"   
                       textColor = 'secondary' 
                       indicatorColor='secondary'
                       >
                   <Tab label= {t("tab_route")} {...a11yProps(0)}  sx={{ fontSize: 12 }}/>
                   <Tab label= {t("tab_vessels")} {...a11yProps(1)} sx={{ fontSize: 12 }} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} >
                  <VerticalTimeLine locations_with_events = {locations_with_events} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} >
                  <VesselComponent vessels={vessels_with_events}  locations={locations}  />
            </CustomTabPanel>
            
         </Box>
     )


}

export default  CustomTabs;