import React,{Fragment} from 'react';
import type {VesselWithEvents, Locations} from '../types';
import { Typography } from '@mui/material';
import {Box} from '@mui/material';
import { Grid ,Divider} from '@mui/material';
import { formatDate } from '../helpers/vesselHelper';
import { VesselTypography, PaperInfoNoData } from '../styles/InfoSideBarStyles';
import { useTranslation } from 'react-i18next';

const VesselComponent: React.FC<{vessels : VesselWithEvents[] , locations: Locations}> = ({vessels , locations}) =>{

    const { t } = useTranslation();

    return(    
        <Box sx={{ height: '350px', overflowY: 'auto',  padding: 2,}} >

                { vessels && vessels.length > 0 ? vessels.map((vessel) => (
                    <Fragment key={vessel.vessel.mmsi}>
                        <Grid container 
                                spacing={4}
                                justifyContent="flex-start"
                                alignItems="baseline"   >
                                <Grid item >
                                    <VesselTypography variant="subtitle2"> {t("vessel")}: </VesselTypography>  
                                    <VesselTypography variant="subtitle2"> {t("vessel_vouage")}: </VesselTypography>  
                                    <VesselTypography variant="subtitle2"> {t("vessel_loading")}:  </VesselTypography>
                                    <VesselTypography variant="subtitle2"> {t("vessel_discharge")}: </VesselTypography>
                                    { vessel.events.length > 0 ? 
                                        <>
                                            <VesselTypography variant="subtitle2"> {vessel?.events[0].actual == true ? "ATD:" : "ETD:" }   </VesselTypography>
                                            <VesselTypography variant="subtitle2"> {vessel?.events[1].actual == true ? "ATA:" : "ETA:" }  </VesselTypography>  
                                        </> 
                                      : 
                                       null 
                                    }
                                </Grid>
                                <Grid item  >
                                    <Typography variant="subtitle2">  {vessel.vessel.name}  </Typography>  
                                    { vessel.events.length > 0 ? 
                                        <>
                                            <Typography variant="subtitle2"> {vessel.events[0].voyage ?? t("no_data")}</Typography>  
                                            <Typography variant="subtitle2">{locations.find(loc => loc.id == vessel.events[0].location)?.name ?? t("no_data")} </Typography>
                                            <Typography variant="subtitle2"> {locations.find(loc => loc.id == vessel.events[1].location)?.name ?? t("no_data") } </Typography>
                                            <Typography variant="subtitle2">  { vessel.events[0].date ? formatDate(vessel.events[0].date) : t("unknown_date") } </Typography>
                                            <Typography variant="subtitle2">  { vessel.events[1].date ? formatDate(vessel.events[1].date) : t("unknown_date")} </Typography>  
                                        </>
                                        :
                                        <PaperInfoNoData elevation={1} > {t("No full information about vessel")} </PaperInfoNoData>
                                     }
                                </Grid>
                        </Grid>
                        <Divider variant="middle" sx={{ my: 2 }} />
                    </Fragment>
                ))
                : 
                <PaperInfoNoData elevation={1} > {t("vessels_no_information_about_vessels")} </PaperInfoNoData>
            }  
        </Box>
    )
}


export default VesselComponent