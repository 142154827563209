import React, {useState} from 'react';
import { MenuItem, Select} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import i18n from 'i18next';



const LanguageSelector: React.FC<{tmslanguage: string | null}> = ({tmslanguage}) => {
     
    const [language , setLanguage] = useState<string | null>(tmslanguage);
   

    const changeLanguage = (language: string) =>{
        i18n.changeLanguage(language);
    }
     
    const handleChange = (event: SelectChangeEvent<string>) =>{
        const selectedLanguage = event.target.value;

        setLanguage(selectedLanguage);
        changeLanguage(selectedLanguage);
    };

    return (
        <Select
          value={language ?? 'en'}
          onChange={handleChange}
          displayEmpty
          style={{ width: 80, height: 30, fontSize: '0.75rem' }}
          sx={{borderRadius: 3,}}
          renderValue={() => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/flags/${language}.gif`}
                alt={`${language} Icon`}
                style={{ width: 16, height: 12, marginRight: 8 }}
              />
            {language ? language.toUpperCase() : ''}
            </div>
          )}
        >
          <MenuItem value="pl">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/flags/pl.gif`}
                alt="PL Icon"
                style={{ width: 16, height: 12, marginRight: 8 }}
              />
              PL
            </div>
          </MenuItem>
          <MenuItem value="en">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/flags/en.gif`}
                alt="EN Icon"
                style={{ width: 16, height: 12, marginRight: 8 }}
              />
               EN
            </div>
          </MenuItem>
        </Select>
      );

}

export default LanguageSelector;