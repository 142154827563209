import {Box} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ErrorBox: React.FC<{ error: string  }> = ({error}) =>{

  const { t } = useTranslation();   
  
   return(
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    bgcolor="error.main"
    color="white"
    p={2}
  >
    {t(error)}
  </Box>
   )
}

export default ErrorBox;