import React from 'react';
import Notification from './Notification';

interface NotificationListProps {
  messages: string[];
}

const NotificationList: React.FC<NotificationListProps> = ({ messages }) => {
  return (
    <div style={{
      position: 'absolute',
      top: '10px',
      right: '10px',
      zIndex: 1000,
    }}>
      {messages.map((message, index) => (
        <Notification key={index} message={message} />
      ))}
    </div>
  );
};

export default NotificationList;