import { MonitoringStatuses, TrackItem } from '../types';
import InfoSideBar from "../components/InfoSideBar";
import MapViewer from "./Map/MapViewer";
import {Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorBox from './ErrorBox';


const GridItems: React.FC<{trackItem: TrackItem , language: string | null , monitoring_status: MonitoringStatuses}> = ({trackItem , language , monitoring_status}) =>{
    
   const { t } = useTranslation();  
   const  {data, message } = trackItem.raw;

    if(message !== 'OK'){
       
      let error = t("auth_error_fetching_data");
        return <ErrorBox error={error} />;
    }

    return (
        <Box sx={{display: 'flex'}}  >
        <Grid container alignItems="stretch" style={{ height: '100vh' }} >
           <Grid item xs={12} md={4} >
                <InfoSideBar container_data={data}  language = {language} monitoring_status = {monitoring_status}/>   
           </Grid>
           <Grid item xs={12} md={8} >
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ flexGrow: 1, marginTop: 1, marginBottom: 1, height:"100vh" }}>
                <MapViewer locations={data.locations}  route_data={data.route_data}/>
              </Box>
         </Box>
           </Grid> 
        </Grid>
     </Box>
    )
       
}


export default GridItems