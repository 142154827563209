
import { Location} from '../types';
import { LatLngExpression} from 'leaflet';



const transformRoute = (route: [number, number][]) =>{
    return  route.map(item => [item[0], item[1]] as LatLngExpression);
  }

  
const findPinIndex = (route: LatLngExpression[], pin: [number, number]) =>
  
      route.findIndex(item => Array.isArray(item) && item[0] === pin[0] && item[1] === pin[1]);


  
const transformLocations = (locations: Location[]) => {
  
        return locations
                  .filter(location => location.lat !== null && location.lng !== null)
                  .map(location => [location.lat, location.lng]  as LatLngExpression);
    
  };

 export{
    transformRoute,
    findPinIndex,
    transformLocations
 }