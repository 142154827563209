import {Box, Typography } from '@mui/material';
import { InfoSideBox } from '../../styles/InfoSideBarStyles';
import {SortedRoutePoint} from '../../types';
import dayjs from 'dayjs';
import i18n from '../../helpers/i18n';
import 'dayjs/locale/pl';
import { useTranslation } from 'react-i18next';


const RouteInfo: React.FC<{ routePoints: SortedRoutePoint[] , sea_line_name: string | null }> = ({ routePoints, sea_line_name }) =>{
     
     const currentLocale = i18n.language;
     dayjs.locale(currentLocale);

     const { t } = useTranslation();

   return(
    <InfoSideBox sx={{paddingTop:1 }}>
    <Box display="flex">
         {routePoints.map((route, index) =>{
              
               let isValidDate = dayjs(route.date).isValid();
               let formattedDate = isValidDate ? dayjs(route.date).format('D MMMM YYYY') : t('route_invalid_date');

             return(
              <Typography key={route.location} variant="body1" fontSize={14} sx={index === 1 ? { paddingLeft: 2 } : {}} >
                  {index === 0
                        ?     
                            route.actual === true  ? `ATD: ${formattedDate}`  : `ETD: ${formattedDate}`

                  : index === routePoints.length - 1
                        ? route.actual === true  ? `ATA: ${formattedDate}` : `ETA: ${formattedDate}`
                        : ''} 
              </Typography>
             )
            } )}
    </Box>
    <Box>
        <Typography variant="body1" fontSize={14}>{sea_line_name ? sea_line_name : t('route_no_sealine_info') }</Typography>
    </Box>
    </InfoSideBox>
   )

}

export default RouteInfo;


